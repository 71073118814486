define(['app', 'formTimer', 'dom'], function(app, FormTimer, dom) {

  describe('formTimerSpec', function() {
    var ft = new FormTimer;

    describe('init', function() {
      it('This function should set up local properties', function() {
        spyOn(ft, 'init').and.callThrough();
        ft.init();
        expect(ft.init).toHaveBeenCalled();
        expect(ft.timeSpent).toBe(null);
        expect(ft.fieldName).toBe(null);
        expect(ft.fieldClass).toBe(null);
        expect(ft.timeSpent).toBe(null);
        expect(ft.fields.length).toBe(0);

      });

    });

    describe('enterField', function() {
      it('This function checks to see if the user has entered a form field', function() {
        spyOn(ft, 'enterField').and.callThrough();
        var ev = {
          target: {
            id: 1
          }
        };
        ft.enterField(ev);

        expect(ft.enterField).toHaveBeenCalled();
        expect(ft.fields.length).toBeGreaterThan(0);
        expect(ft.fields[0].target).toBe(1);
        expect(ft.fields[0].startTime).not.toBe(null);
        expect(ft.fields[0].timeSpent).toBe(null);
      });
    });


    describe('exitField', function() {
      it('This function checks to see if user has clicked on another element and passes data to object properties', function() {
        spyOn(ft, 'exitField').and.callThrough();
        ft.exitField();
        expect(ft.exitField).toHaveBeenCalled();
        var fieldsLength = 1;
        var pTime = {
          startTime: 1
        };
        var timeInt = pTime.startTime;

        expect(fieldsLength).toBeGreaterThan(0);
        expect(pTime).not.toBe(null);
        expect(timeInt.startTime).not.toBe(null);
      });
    });

    describe('listen', function() {
      it('This function sets up the event listener functions', function() {
        var element = document.getElementById('unit-test');
        spyOn(app, ['on']).and.callThrough();

        ft.listen(element);

        expect(app.on).toHaveBeenCalledWith('focus', ft.listenEnter, element, true);
        expect(app.on).toHaveBeenCalledWith('blur', ft.listenExit, element, true);
        expect(app.on).toHaveBeenCalledWith('change', ft.listenExit, element, true);
      });

      it('Should do nothing if empty object is sent', function() {
        var element = document.getElementById('unit-test');
        spyOn(app, ['on']);

        ft.listen();

        expect(app.on).not.toHaveBeenCalled();
      })
    });

    describe('timerValues', function() {

      dom.render();

      it('This function should NOT pass event values to form timers', function() {
        var obj = {};
        var ft = new FormTimer();
        obj.target = document.querySelector('.this-element-does-not-exist');
        obj.type = 'click';
        spyOn(app.element, 'closestI').and.callThrough();
        ft.timerValues(obj);
        expect(app.element.closestI).not.toHaveBeenCalled();
        expect(ft.fieldId).toBe(undefined);
        expect(ft.fieldName).toBe(undefined);
        expect(ft.fieldClass).toBe(undefined);
        expect(ft.leaveType).toBe(undefined);
        expect(ft.category).toBe(undefined);
      });

      it('This function should NOT pass event values to form timers', function() {
        var obj = {};
        var ft = new FormTimer();
        var elem = document.createElement('button');
        elem.id = 'formTimerRogueElem';
        obj.target = elem;
        obj.type = 'click';
        spyOn(app.element, 'closestI');

        ft.timerValues(obj);

        expect(app.element.closestI).toHaveBeenCalled();
        expect(ft.fieldId).toBe(undefined);
        expect(ft.fieldName).toBe(undefined);
        expect(ft.fieldClass).toBe(undefined);
        expect(ft.leaveType).toBe(undefined);
        expect(ft.category).toBe(undefined);
      });
    });
  });
});
